<template>
	<div class="h-full flex flex-col relative" v-if="user">
		<TopBar :showBack="true"/>
		<AppTitle :title="user.name"/>
		<div class="pt-8 overflow-y-scroll flex-grow" v-if="user">
      <div class="pb-8 text-center">
        <div class="w-full">
          <user-profile-picture 
            :user="user"
            :size="32"
          />
        </div>
      </div>
      <div class="space-y-4">
        <div 
          v-if="isFriend"
          class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4 cursor-pointer"
          @click="unfriend(user)"
        >
          <p class="font-bold flex-1">Remove Friend</p>
          <font-awesome-icon icon="fa-solid fa-circle-minus" class="text-red-500 text-sm flex-0"/>
        </div>
        <div 
          v-if="iSentFriendRequest"
          class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4"
        >
          <p class="font-bold flex-1">Friend Request Sent</p>
          <font-awesome-icon icon="fa-solid fa-clock" class=" text-sm flex-0"/>
        </div>
        <div 
          v-if="sentMeFriendRequest"
          class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4 cursor-pointer"
          @click="acceptFriendRequest(user)"
        >
          <p class="font-bold flex-1">Accept Friend Request</p>
          <font-awesome-icon icon="fa-solid fa-check" class="text-green-500 text-lg flex-0"/>
        </div>
        <div 
          v-if="sentMeFriendRequest"
          class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4 cursor-pointer"
          @click="denyFriendRequest(user)"
        >
          <p class="font-bold flex-1">Deny Friend Request</p>
          <font-awesome-icon icon="fa-solid fa-close" class="text-red-500 text-lg flex-0"/>
        </div>
        <div 
          v-if="!isFriend && !iSentFriendRequest && !sentMeFriendRequest"
          class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4 cursor-pointer"
          @click="sendFriendRequest(user)"
        >
          <p class="font-bold flex-1">Send Friend Request</p>
          <font-awesome-icon icon="fa-solid fa-chevron-right" class=" text-sm flex-0"/>
        </div>
      </div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
  import UserProfilePicture from '@/components/UserProfilePicture.vue';
	export default {
		components: {
			TopBar,
			AppTitle,
      UserProfilePicture,
		},
    data(){
      return {
        user: null,
        isFriend: null,
        iSentFriendRequest: null,
        sentMeFriendRequest: null
      }
    },
    created(){
      if (this.$route.params.uuid === this.$store.state.auth.user.uuid){
        this.$router.replace({name: 'profile'});
        return;
      }
      this.getUser();
    },
    methods: {
      getUser(){
        this.$store.dispatch('profile/getUser', {
          uuid: this.$route.params.uuid
        }).then( ({data}) => {
          this.user = data.user;
          this.isFriend = data.isFriend;
          this.iSentFriendRequest = data.iSentFriendRequest;
          this.sentMeFriendRequest = data.sentMeFriendRequest;
        });
      },
      sendFriendRequest(user){
        this.$store.dispatch('friends/sendFriendRequest', user.uuid).then( () => {
          this.$store.dispatch('friends/getFriends');
          this.getUser();
        });
      },
      acceptFriendRequest(user){
        this.$store.dispatch('friends/acceptFriendRequest', user.uuid).then( () => {
          this.$store.dispatch('friends/getFriends');
          this.getUser();
        });
      },
      denyFriendRequest(user){
        this.$store.dispatch('friends/denyFriendRequest', user.uuid).then( () => {
          this.$store.dispatch('friends/getFriends');
          this.getUser();
        });
      },
      unfriend(user){
        this.$store.dispatch('friends/unfriend', user.uuid).then( () => {
          this.$store.dispatch('friends/getFriends');
          this.getUser();
        });
      }
    }
	}
</script>